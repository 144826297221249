<template>
	<div>
		<div class="">
			<div class="crumbs">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item>
						<i class="el-icon-lx-cascades"></i> 权限管理
					</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="handle-box">
				<el-button type="primary" icon="el-icon-lx-add" class="handle-del mr10" @click="menuVisible=true">创建菜单
				</el-button>
				<!-- <el-button type="primary" icon="el-icon-lx-add" class="handle-del mr10" @click="route()">生成路由权限</el-button> -->
			</div>
			<el-table :data="treeMenu" style="width: 100%;margin-bottom: 30px;" border row-key="id"
				:default-expand-all="false" :tree-props="{children: 'lists', hasChildren: 'hasChildren'}">
				<el-table-column prop="name" width="300px" label="菜单名称">
					<template #default="scope">
						<el-button v-if="scope.row.pid==0" type="text" icon="el-icon-lx-add"
							@click="menuVisible=true;menuForm.parentName=scope.row.name;menuForm.pid=scope.row.id">
						</el-button>&nbsp;&nbsp;{{scope.row.name}}
						<span v-if="scope.row.is_show == 1" style="color: blue">（显示）</span>
						<span v-else style="color: red">（隐藏）</span>
						<span style="float: right;">
							<el-button v-if="scope.row.lists.length==0" type="text" icon="el-icon-lx-add"
								@click="permissionVisible=true;permissionMenu=scope.row.name;permissionForm.menu_id=scope.row.id">
								权限</el-button>
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="url" width="200px" label="菜单路由"></el-table-column>
				<el-table-column prop="name" label="菜单权限">
					<template #default="scope">
						<span v-for="(item,index) in scope.row.operations" style="margin-left: 10px;" :key="index">
							<el-row style="margin-left: 10px;">
								<el-col :span="8">{{index+1}}. {{item.name}}</el-col>
								<el-col :span="8">{{item.action}}</el-col>
								<el-col :span="2">
									<el-button type="text" icon="el-icon-edit"
										@click="permissionVisible=true;permissionForm=item;permissionMenu=scope.row.name;">
									</el-button>
									<el-button type="text" icon="el-icon-delete" class="red"
										@click="permissionDel(item.id,item.name)"></el-button>
								</el-col>
							</el-row>
						</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="180" align="center">
					<template #default="scope">
						<el-button type="text" icon="el-icon-edit" @click="editMenu(scope.row)">编辑</el-button>
						<el-button type="text" icon="el-icon-delete" class="red"
							@click="menuDel(scope.row.id,scope.row.name)">删除</el-button>
						<el-button type="text" @click="changeShow(scope.row.id)">{{scope.row.is_show?'隐藏':'显示'}}
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<!-- 创建菜单 -->
		<el-dialog title="菜单管理" v-model="menuVisible" width="30%" :before-close="handleClose">
			<el-form ref="form" :model="menuForm" label-width="70px">
				<el-form-item label="上级菜单">
					<el-select v-model="menuForm.pid" filterable placeholder="请选择">
						<el-option key="顶级菜单" label="顶级菜单" :value="0">
						</el-option>
						<el-option v-for="item in selectMenu" :key="item.name" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="菜单名称">
					<el-input v-model="menuForm.name"></el-input>
				</el-form-item>
				<el-form-item label="菜单路由">
					<el-input v-model="menuForm.url"></el-input>
				</el-form-item>
				<el-form-item label="图标">
					<el-button @click="iconVisible = true">选择图标</el-button>
					<el-input v-model="menuForm.icon" style="display:none"></el-input>
					<span style="width:20px;height:20px;margin-left:10px;"><i :class="menuForm.icon"
							style="width:20px;height:20px;"></i></span>
				</el-form-item>
				<el-form-item label="路由排序">
					<el-input-number v-model="menuForm.sort" :min="1" :max="100000" label="排序值"></el-input-number>
				</el-form-item>
				<el-form-item label="菜单显示">
					<el-radio v-model="menuForm.is_show" :label="1">显示</el-radio>
					<el-radio v-model="menuForm.is_show" :label="0">隐藏</el-radio>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="handleClose">取 消</el-button>
					<el-button type="primary" @click="saveMenu">确 定</el-button>
				</span>
			</template>
		</el-dialog>
		<!-- 创建权限 -->
		<el-dialog title="权限管理" v-model="permissionVisible" width="30%" :before-close="handleClose">
			<el-form ref="form" :model="permissionForm" label-width="80px">
				<el-form-item label="所属菜单">
					<!-- <el-select v-model="permissionForm.menu_id" filterable placeholder="请选择">
                        <el-option key="顶级菜单" label="顶级菜单" value="0">
                        </el-option>
                        <el-option v-for="item in selectMenu" :key="item.name" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select> -->
					<el-input v-model="permissionMenu" disabled></el-input>
				</el-form-item>
				<el-form-item label="权限名称">
					<el-input v-model="permissionForm.name"></el-input>
				</el-form-item>
				<el-form-item label="权限action">
					<el-input v-model="permissionForm.action"></el-input>
				</el-form-item>
				<el-form-item label="权限url">
					<el-input v-model="permissionForm.url"></el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="handleClose">取 消</el-button>
					<el-button type="primary" @click="savePermission">确 定</el-button>
				</span>
			</template>
		</el-dialog>
		<!-- 选择图标 -->
		<el-dialog title="图标" v-model="iconVisible" width="60%">
			<div>
				<div class="crumbs">
					<el-breadcrumb separator="/">
						<el-breadcrumb-item><i class="el-icon-lx-emoji"></i> 自定义图标</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="container">
					<div class="search-box">
						<el-input class="search" size="large" v-model="keyword" clearable placeholder="请输入图标名称">
						</el-input>
					</div>
					<ul>
						<li class="icon-li" v-for="(item,index) in list" :key="index">
							<div class="icon-li-content" @click="selectIcon(item)">
								<i :class="`el-icon-lx-${item}`"></i>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</el-dialog>
	</div>
</template>


<script>
	export default {
		data() {
			return {
				treeMenu: [],
				menuVisible: false,
				menuForm: {},
				permissionVisible: false,
				permissionForm: {},
				selectMenu: [],
				iconVisible: false,
				permissionMenu: '',
				iconList: [
					'attentionforbid',
					'attentionforbidfill',
					'attention',
					'attentionfill',
					'tag',
					'tagfill',
					'people',
					'peoplefill',
					'notice',
					'noticefill',
					'mobile',
					'mobilefill',
					'voice',
					'voicefill',
					'unlock',
					'lock',
					'home',
					'homefill',
					'delete',
					'deletefill',
					'notification',
					'notificationfill',
					'notificationforbidfill',
					'like',
					'likefill',
					'comment',
					'commentfill',
					'camera',
					'camerafill',
					'warn',
					'warnfill',
					'time',
					'timefill',
					'location',
					'locationfill',
					'favor',
					'favorfill',
					'skin',
					'skinfill',
					'news',
					'newsfill',
					'record',
					'recordfill',
					'emoji',
					'emojifill',
					'message',
					'messagefill',
					'goods',
					'goodsfill',
					'crown',
					'crownfill',
					'move',
					'add',
					'hot',
					'hotfill',
					'service',
					'servicefill',
					'present',
					'presentfill',
					'pic',
					'picfill',
					'rank',
					'rankfill',
					'male',
					'female',
					'down',
					'top',
					'recharge',
					'rechargefill',
					'forward',
					'forwardfill',
					'info',
					'infofill',
					'redpacket',
					'redpacket_fill',
					'roundadd',
					'roundaddfill',
					'friendadd',
					'friendaddfill',
					'cart',
					'cartfill',
					'more',
					'moreandroid',
					'back',
					'right',
					'shop',
					'shopfill',
					'question',
					'questionfill',
					'roundclose',
					'roundclosefill',
					'roundcheck',
					'roundcheckfill',
					'global',
					'mail',
					'punch',
					'exit',
					'upload',
					'read',
					'file',
					'link',
					'full',
					'group',
					'friend',
					'profile',
					'addressbook',
					'calendar',
					'text',
					'copy',
					'share',
					'wifi',
					'vipcard',
					'weibo',
					'remind',
					'refresh',
					'filter',
					'settings',
					'scan',
					'qrcode',
					'cascades',
					'apps',
					'sort',
					'searchlist',
					'search',
					'edit'
				],
				keyword: '',
			}
		},
		computed: {
			list() {
				return this.iconList.filter((item) => {
					return item.indexOf(this.keyword) !== -1;
				})
			}
		},
		created() {
			this.getMenuList()
			this.getSelect()
		},
		methods: {
			// 获取上级菜单列表
			getSelect() {
				this.$axios({
					url: 'admin/menu/getSelect',
					method: 'get',
				}).then(({
					code,
					data
				}) => {
					if (code == 200) {
						// console.log(data)
						this.selectMenu = data
					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});
			},
			getMenuList() {
				this.$axios({
					url: 'admin/menu/getList',
					method: 'get',
				}).then(({
					code,
					data
				}) => {
					if (code == 200) {
						console.log(data)
						this.treeMenu = data
					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});
			},
			// 切换菜单显示隐藏
			changeShow(id) {
				this.$axios({
					url: 'admin/menu/isShow',
					method: 'get',
					params: {
						id
					}
				}).then(({
					code,
					msg
				}) => {
					if (code == 200) {
						this.$message.success(msg)
						this.getMenuList()
					} else {
						//this.$message.error(res.msg)
					}
				})
			},
			// 关闭编辑
			handleClose() {
				this.menuVisible = false
				this.permissionVisible = false

				this.menuForm = {}
				this.permissionForm = {}
			},
			// 编辑菜单按钮
			editMenu(row) {
				this.menuForm = JSON.parse(JSON.stringify(row))
				this.menuVisible = true
			},
			// 删除菜单
			menuDel(id) {
				this.$confirm('此操作将永久删除该菜单, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios({
						url: 'admin/menu/del',
						method: 'DELETE',
						data: {
							id
						}
					}).then(({
						code,
						msg
					}) => {
						if (code == 200) {
							this.$message.success(msg)
							this.getMenuList()
						} else {
							//this.$message.error(res.msg)
						}
					})
				})
			},
			// 删除权限
			permissionDel(id) {
				this.$confirm('此操作将永久删除该菜单权限, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$axios({
						url: 'admin/menu/delOperation',
						method: 'DELETE',
						data: {
							id
						}
					}).then(({
						code,
						msg
					}) => {
						if (code == 200) {
							this.$message.success(msg)
							this.getMenuList()
						} else {
							//this.$message.error(res.msg)
						}
					})
				})
			},
			// 保存编辑
			saveMenu() {
				let url;
				if (this.menuForm.id) {
					url = 'admin/menu/edit'
				} else {
					url = 'admin/menu/create'
				}
				this.$axios({
					url,
					method: 'post',
					data: {
						...this.menuForm
					}
				}).then(({
					code,
					msg
				}) => {
					if (code == 200) {
						this.$message.success(msg)
						this.handleClose()
						this.getMenuList()
					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});
			},
			// 选择图标
			selectIcon(icon) {
				this.menuForm.icon = 'el-icon-lx-' + icon;
				this.iconVisible = false;
			},
			// 保存权限
			savePermission() {
				let url;
				if (this.permissionForm.id) {
					url = 'admin/menu/editOperation'
				} else {
					url = 'admin/menu/createOperation'
				}
				this.$axios({
					url,
					method: 'post',
					data: {
						...this.permissionForm
					}
				}).then(({
					code,
					msg
				}) => {
					if (code == 200) {
						this.$message.success(msg)
						this.handleClose()
						this.getMenuList()
					} else {
						//this.$message.error(res.msg)
					}
				}).catch(err => {
					console.log(err)
				});
			},
		}
	}
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 14px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
	}

	.table-td-thumb {
		display: block;
		margin: auto;
		width: 40px;
		height: 40px;
	}

	.icon-li {
		display: inline-block;
		padding: 20px;
		width: 5px;
		height: 5px;
	}

	.icon-li-content {
		display: flex;
		height: 100%;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	.icon-li-content i {
		font-size: 24px;
		color: #606266;
	}

	.icon-li-content span {
		margin-top: 10px;
		color: #787878;
	}

	.el-input--small {
		width: 70% !important;
	}
</style>
